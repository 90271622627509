<h1 *ngIf="data.title">{{data.title}}</h1>

<ng-container *ngIf="data.dialogType==='general'">
  <div mat-dialog-content>
    <p *ngIf="data.content">{{data.content}}</p>
  </div>
  <div mat-dialog-actions *ngIf="data.buttonOne || data.buttonTwo">
    <div class="save-cancel-button-group">
      <button mat-flat-button id="buttonOne" *ngIf="data.buttonOne" color="primary" [disableRipple]="true"
        [mat-dialog-close]="data.true"
        (click)="onButtonClick({'buttonClicked': data.true,'returnedData': data})">{{data.buttonOne}}</button>
      <button mat-button id="buttonTwo" *ngIf="data.buttonTwo" color="primary" [disableRipple]="true"
        [mat-dialog-close]="data.false"
        (click)="onButtonClick({'buttonClicked': data.false,'returnedData': data})">{{data.buttonTwo}}</button>
    </div>
  </div>
</ng-container>