<mat-sidenav-container [hasBackdrop]="true" class="example-container" (backdropClick)="close()">
  <mat-sidenav #sidenav mode="over" position="end" [(opened)]="opened">

    <mat-list>
      <h3 mat-subheader>Categories</h3>
      <mat-list-item>
        <p mat-line><button mat-button class="sidenav-button" (click)="catDetail(0)">Breakfast</button></p>
        <p mat-line><button mat-button class="sidenav-button" (click)="catDetail(1)">Appetizers</button></p>
        <p mat-line><button mat-button class="sidenav-button" (click)="catDetail(2)">Soups</button></p>
        <p mat-line><button mat-button class="sidenav-button" (click)="catDetail(3)">Dinner</button></p>
        <p mat-line><button mat-button class="sidenav-button" (click)="catDetail(4)">Baked Goods</button></p>
        <p mat-line><button mat-button class="sidenav-button" (click)="catDetail(5)">Deserts</button></p>
        <p mat-line><button mat-button class="sidenav-button" (click)="catDetail(6)">Sausage</button></p>
        <p mat-line><button mat-button class="sidenav-button" (click)="catDetail(7)">Snacks</button></p>
        <p mat-line><button mat-button class="sidenav-button" (click)="catDetail(8)">Misc</button></p>
      </mat-list-item>
      <mat-divider></mat-divider>
      <h3 mat-subheader>Groceries</h3>
      <mat-list-item>
        <p mat-line><button mat-button class="sidenav-button" (click)="groceries()">View List</button></p>
      </mat-list-item>
      <h3 mat-subheader>User</h3>
      <mat-list-item>
        <p mat-line *ngIf="authCheck()">
          <button mat-button (click)="logout()">Log Out</button>
        </p>
        <p mat-line *ngIf="!authCheck()">
          <button mat-button (click)="login()">Log In</button>
        </p>
      </mat-list-item>
    </mat-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <header id="top" class="container-fluid">
      <div class="header-content">
        <div class="header-logo">
          <img src="./assets/images/chef-dad-logo.svg" (click)="home()" />
          <button class="add-recipe-button" type="button" mat-button (click)="addRecipe()"
            *ngIf="router.url.indexOf('/login') <= -1">
            <mat-icon aria-label="Side nav toggle icon">add_circle_outline</mat-icon>Add Recipe
          </button>
        </div>
        <button class="main-menu" type="button" mat-icon-button (click)="sidenav.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
      </div>
    </header>

    <main [@routerTransition]="getRouterOutletState(o)">

      <router-outlet #o="outlet"></router-outlet>

    </main>

    <!-- <footer>
      &copy; {{copyrightYear}} ChefDad.net
    </footer> -->
  </mat-sidenav-content>
</mat-sidenav-container>