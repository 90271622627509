import { Component, ViewChild, HostBinding, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { routerTransition } from 'src/app/shared/animation/animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition]
})

export class AppComponent implements OnInit {
  @HostBinding('class')
  componentCssClass: string;

  @ViewChild('sidenav') sidenav: MatSidenav;
  title = 'Chef Dad';
  copyrightYear = new Date().getFullYear();
  events: string[] = [];
  opened: boolean;
  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  constructor(
    public router: Router,
    public authService: AuthService
  ) { }

  ngOnInit() { }

  authCheck() {
    if (this.authService.isLoggedIn) {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    this.authService.logout();
    this.close();
  }

  login() {
    this.router.navigate(['']);
    this.close();
  }

  groceries() {
    this.router.navigate(['/grocery-list']);
    this.close();
  }

  close() {
    this.sidenav.close();
  }

  home() {
    this.router.navigate(['/latest']);
  }

  addRecipe() {
    this.router.navigate(['/add-recipe']);
  }

  catDetail(id: number) {
    this.router.navigate(['/category-detail/list/' + id]);
    this.sidenav.close();
  }


}
