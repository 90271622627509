import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { finalize, map, catchError } from 'rxjs/operators';
import { LoadingIndicatorComponent } from '../components/loading-indicator/loading-indicator.component';
import { ComponentLoadingService } from '../services/component-loading/component-loading.service';
// import { AnimStateService } from '../services/anim-state/anim-state.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  public errorCaught = false;
  private loadingService: ComponentLoadingService;
  constructor(
    // public broadcast: AnimStateService,
    private readonly injector: Injector
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService = this.injector.get(ComponentLoadingService);
    if (!this.loadingService.getLoading()) {
      this.loadingService.open(LoadingIndicatorComponent);
    }
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //
        }
        // this.broadcast.onNotify('out');
        return event;
      }),
      // catchError((error: HttpErrorResponse) => {
      //
      //   this.errorCaught = true;
      //   return Observable.throw(error.statusText);
      // }),
      finalize(() => {
        // if (this.errorCaught === false) {
        //
        //   this.broadcast.onNotify('in');
        // } else {
        //   this.errorCaught = false;
        // }
        this.loadingService.close();
      })
    );
  }
}
