import { Component, Inject, OnInit, OnDestroy, EventEmitter, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-generic',
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.scss']
})
export class GenericDialogComponent implements OnInit, OnDestroy {

  otherReason: string;
  event = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    private readonly renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    /** Add class to body */
    this.renderer.addClass(document.body, 'generic-msg');
  }

  ngOnInit() {
    this.data.false = false;
    this.data.true = true;
  }

  /** Emits what button is clicked */
  onButtonClick(response: Object) {
    this.event.emit(response);
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'generic-msg');
  }

  close() {
    console.log('Close Dialog Initiated');
  }

}
