import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ComponentLoadingService {

  componentRef: any;
  public isLoading = false;
  private readonly _event = new Subject<any>();
  public get event(): Observable<any> { return this._event.asObservable(); }

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly appRef: ApplicationRef,
    private readonly injector: Injector
  ) {
  }

  open(component: any) {
    // Timeout allows for other animations to complete
    if (this.isLoading === false) {
      this.isLoading = true;
      setTimeout(() => { this.createLoader(component); });
    }
  }

  getLoading() {
    return this.isLoading;
  }

  createLoader(component: any) {
    if (this.componentRef != null) {
      this.componentRef.destroy();
    }
    // Create a component reference from the component
    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(this.componentRef.hostView);

    // Get DOM element from component
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);

    // Passes a reference of itself to the newly created component
    this.componentRef.instance.selfReference = this.componentRef;
  }

  close() {
    this.isLoading = false;
    this._event.next('out');
    if (this.componentRef != null) {
      setTimeout(() => {
        this._event.next('in');
        this.componentRef.destroy();
      }, 500);
    }
  }

}
