import { NgModule } from '@angular/core';

import {
  MatListModule,
  MatMenuModule,
  MatSidenavModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatCheckboxModule,
  MatSelectModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatCardModule,
  MatDialogModule,
  MatButtonModule,
  MatBadgeModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatTreeModule,
  MatToolbarModule,
  MatSnackBarModule
} from '@angular/material';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  imports: [
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatTreeModule,
    MatToolbarModule,
    MatSnackBarModule
  ],
  exports: [
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatTreeModule,
    MatToolbarModule,
    MatSnackBarModule
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false },
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'en-US'
    }
  ]
})
export class MaterialModule { }
