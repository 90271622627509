// setup taken from: https://www.techiediaries.com/angular-course-firebase-authentication/
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  user: User;

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router
  ) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.setItem('user', null);
      }
    });
  }

  async  login(email: string, password: string, intendedUrl?: any) {
    try {
      await this.afAuth.auth.signInWithEmailAndPassword(email, password);
      if (intendedUrl) {
        const re = /%/g;
        const processedUrl = intendedUrl.replace(re, '/');
        console.log(processedUrl);
        this.router.navigate(['/' + processedUrl]);
      } else {
        this.router.navigate(['/latest']);
      }
    } catch (error) {
      alert('Error!' + error.message);
    }
  }

  async logout() {
    await this.afAuth.auth.signOut();
    localStorage.removeItem('user');
    this.router.navigate(['admin/login']);
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
  }

}
