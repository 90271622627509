import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { GenericDialogComponent } from './dialogs/generic/generic.component';
import { ReversePipe } from './pipes/reverse.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ImagesLoadedDirective } from './directives/images-loaded.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from './http-interceptors/loading.class';
import { ComponentLoadingService } from './services/component-loading/component-loading.service';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';

@NgModule({
  declarations: [
    GenericDialogComponent,
    ReversePipe,
    TruncatePipe,
    ImagesLoadedDirective,
    LoadingIndicatorComponent
    // Components, directives, pipes
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    ComponentLoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
  ],
  exports: [
    // Directives, pipes, components and  modules
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ReversePipe,
    TruncatePipe,
    ImagesLoadedDirective,
    LoadingIndicatorComponent
  ],
  entryComponents: [
    // Dynamic entry components such as modals, dialogs, etc.
    GenericDialogComponent,
    LoadingIndicatorComponent
  ]
})
export class SharedModule { }
