import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';

import { loadingFade } from '../../animation/animation';
import { ComponentLoadingService } from '../../services/component-loading/component-loading.service';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  animations: [
    loadingFade,
  ]
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy {

  selfReference: any;
  state = 'out';

  constructor(
    private readonly renderer: Renderer2,
    private readonly loading: ComponentLoadingService
  ) {
    this.renderer.addClass(document.body, 'no-scroll');
    this.renderer.addClass(document.body, 'loading');
  }

  ngOnInit() {
    const loadingEvent = this.loading.event
      .subscribe(
        (value: string) => {
          if (value === 'out') {
            this.state = 'out';
          }
        }
      );
    setTimeout(() => this.state = 'in');
    if (this.state === 'out') {
      loadingEvent.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'no-scroll');
    this.renderer.removeClass(document.body, 'loading');
  }

}

