import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: './auth/auth.module#AuthModule',
  },
  {
    path: 'latest',
    loadChildren: './latest/latest.module#LatestModule'
  },
  {
    path: 'add-recipe',
    loadChildren: './add-recipe/add-recipe.module#AddRecipeModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-recipe',
    loadChildren: './edit-recipe/edit-recipe.module#EditRecipeModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'recipe-detail',
    loadChildren: './recipe-detail/recipe-detail.module#RecipeDetailModule'
  },
  {
    path: 'category-detail',
    loadChildren: './category-detail/category-detail.module#CategoryDetailModule'
  },
  {
    path: 'grocery-list',
    loadChildren: './grocery-list/grocery-list.module#GroceryListModule',
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
